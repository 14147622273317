/* job card */
.jobcard{
    margin: auto;
    /* width: 800px; */
    width: 100%;
   
  }
  .innerjob{
    padding: 6px 0px ;
    background:#fff ;
    border: 2px solid #000;
    border-radius: 10px;
    box-shadow: #fff;
   
  
  }
  
  .iner{
    display: flex;
    align-items: start;
    justify-content: start;
   
  }
  #button{
    background-color: #32A852;
    color:white
    }
    
    #button:hover{
    background-color: white;
    color: #32A852;
    border-color: #32A852;
    
    }



    
  