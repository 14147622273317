#col, #navbarDropdown {

  display: inline-block;
  color: #fff;
  font-weight:650;
 
margin-left: 60px;
}
.vertical-line {
  border-left: 1px solid #32A852;
  height: 100%;
  margin-left: 60px;
  }
    /* SHIFT */
    nav ul li a {
      position:relative;
      z-index: 1;
      }
      nav ul li a:hover {
      color: linear-gradient(to right, rgb(15, 23, 42), #32A852, rgb(15, 23, 42));
      }
      nav ul li a:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 1px;
      content: '.';
      color: transparent;
      background: linear-gradient(to right, rgb(15, 23, 42), #32A852, rgb(15, 23, 42));
      visibility: none;
      opacity: 0;
      z-index: -1;
      }
      nav ul li a:hover:after {
      opacity: 1;
      visibility: visible;
      height: 100%;
      }